import React from 'react';
import { useLocation } from 'react-router-dom';
import { useComponentWillUnmount } from '../../../utils/hooks/component-lifecycle';
import { usePageViewAnalytics } from '../../../analytics';
import { useActions } from '../../../application-state';
import { I18nKey } from '../../../i18n';
import { FormPagePanel } from '../../../core-components';
import { Login } from '../../../account';
import { WwwFormPage, WwwFormPagePanelSection } from '../../page';
import dataTestPage from './dataTestPage';

export const LoginPage = ({ fullWidth }) => {
  const { clearErrors } = useActions();
  const location = useLocation();

  usePageViewAnalytics('login-index', { isSensitive: true });

  useComponentWillUnmount(() => {
    clearErrors();
  });

  return (
    <div data-test-page={dataTestPage(location)}>
      <WwwFormPage pageTitleI18nKey={I18nKey.LoginWithShutterstock} contentPlacement="center" fullWidth={fullWidth}>
        <FormPagePanel>
          <WwwFormPagePanelSection fullWidth={fullWidth}>
            <Login fullWidth={fullWidth} />
          </WwwFormPagePanelSection>
        </FormPagePanel>
      </WwwFormPage>
    </div>
  );
};
