import React, { useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n';
import { useToggle } from '../../utility/hooks';

export const PasswordInput = ({
  error,
  floatLabel = false,
  label,
  value,
  onChange,
  onToggleShowPassword,
  margin,
  ...props
}) => {
  const { t: translate } = useTranslation();
  const [showPassword, toggleShowPassword] = useToggle();

  const handleToggleShowPassword = useCallback(
    (e) => {
      toggleShowPassword();

      if (onToggleShowPassword) {
        onToggleShowPassword(!showPassword);
      }

      e.stopPropagation();
    },
    [showPassword, toggleShowPassword, onToggleShowPassword],
  );

  label = label || translate(I18nKey.Password);

  if (Array.isArray(error)) {
    error = error[0];
  }

  const toggleShowPasswordLabel = showPassword ? I18nKey.HidePassword : I18nKey.ShowPassword;

  return (
    <>
      <FormControl fullWidth margin={margin} error={!!error}>
        <InputLabel htmlFor="bb75005925fb-password" shrink={!floatLabel}>
          {label}
        </InputLabel>
        <Input
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleToggleShowPassword}
                size="large"
                aria-label={translate(toggleShowPasswordLabel)}>
                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={props.inputProps}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </>
  );
};
