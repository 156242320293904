import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useComponentWillUnmount } from '../../../utils/hooks/component-lifecycle';
import { nextUrlVar, useActions, useApi, useApplicationConfig } from '../../../application-state';
import { I18nKey } from '../../../i18n';
import { WwwFormPage, WwwFormPagePanelSection } from '../../page';
import { CreateUser } from '../../../account';
import { useNavigate } from 'react-router-dom';
import { usePageViewAnalytics } from '../../../analytics';
import { FormPagePanel } from '../../../core-components';
import { CreateUserValueProps } from '../../../customization/CreateUserValueProps';
import classes from './CreateUserPage.module.scss';
import { OverlayLoader } from '../../../core-components/OverlayLoader';

export const CreateUserPage = ({ fullWidth }) => {
  const api = useApi();
  const navigate = useNavigate();
  const verificationCode = new URLSearchParams(window.location.search).get('verification_code');

  const {
    'createUser.showValueProps': showCreateUserValueProps,
    'createUser.showDisplayName': showDisplayName,
    'createUser.showFullName': showFullName,
    'createUser.openLinksInNewTab': openLinksInNewTab,
    'createUser.showFormTitle': showFormTitle,
    openSocialLoginInNewTab,
    showFormBorders,
    showSocialLogin,
  } = useApplicationConfig();

  const nextUrl = useReactiveVar(nextUrlVar);

  const { clearErrors } = useActions();

  const [loading, setLoading] = useState(true);
  let [invitation, setInvitation] = useState(null);

  useEffect(() => {
    if (!verificationCode) {
      return setLoading(false);
    }

    const url = `/web/api/organizations/verifications/${encodeURIComponent(verificationCode)}`;

    api.http
      .get(url)
      .then((response) => {
        let invitation = response.data;

        setInvitation(invitation);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (!err.response || err.response.status !== 404) {
          throw err;
        }
      });
  }, [verificationCode, api.http, navigate]);

  usePageViewAnalytics('users-new', { isSensitive: true });

  useComponentWillUnmount(() => {
    clearErrors();
  });

  useEffect(() => {
    if (!loading && verificationCode && !invitation) window.location.href = nextUrl;
  }, [loading, invitation, nextUrl, verificationCode]);

  if (loading) {
    return <OverlayLoader />;
  }

  const showNoBorderPadding = !showFormBorders && showCreateUserValueProps;

  return (
    <WwwFormPage pageTitleI18nKey={I18nKey.CreateAnAccount} contentPlacement="center" fullWidth={fullWidth}>
      <FormPagePanel>
        {showCreateUserValueProps && (
          <WwwFormPagePanelSection
            className={`${classes.hiddenXxs} ${showNoBorderPadding ? classes.noBorderPadding : ''}`}
            fullWidth={fullWidth}>
            <div style={{ maxHeight: '640px', overflow: 'auto' }}>
              <CreateUserValueProps />
            </div>
          </WwwFormPagePanelSection>
        )}
        <WwwFormPagePanelSection
          className={`${showNoBorderPadding ? classes.noBorderPadding : ''}`}
          fullWidth={fullWidth}>
          <CreateUser
            showSocialLogin={showSocialLogin}
            showFormTitle={showFormTitle}
            showFullName={showFullName}
            showDisplayName={showDisplayName}
            openLinksInNewTab={openLinksInNewTab}
            openSocialLoginInNewTab={openSocialLoginInNewTab}
            invitation={invitation}
          />
        </WwwFormPagePanelSection>
      </FormPagePanel>
    </WwwFormPage>
  );
};
