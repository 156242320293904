import React, { useEffect, useCallback } from 'react';
import joi from 'joi';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n';
import { AnalyticsTracker } from '../../analytics/trackers';
import { EmailInput, PasswordInput } from '../../core-components/form';
import { formatValidationErrorMessagesByKey, useFormValidation } from '../../core-components/form';

const emailValidationSchema = joi
  .string()
  .email({ tlds: { allow: false } })
  .required();
const passwordValidationSchema = joi.string().min(8).required();

const mergeErrors = (errors, validationErrors) => {
  errors = errors || {};
  validationErrors = validationErrors || {};

  return Array.from(new Set([...Object.keys(errors), ...Object.keys(validationErrors)])).reduce((mergedErrors, key) => {
    mergedErrors[key] = [errors[key], ...(validationErrors[key] || []), ...(mergedErrors[key] || [])].filter(
      (error) => error,
    );
    return mergedErrors;
  }, {});
};

export const CredentialsInput = ({
  errors,
  email = '',
  disableEmail = false,
  password = '',
  autoFocus = true,
  showPassword = true,
  showErrors,
  preventAutofill = false,
  onChange,
  translations = {},
}) => {
  const initialState = { email };
  if (showPassword) {
    initialState.password = password;
  }

  let validationSchema;
  if (showPassword) {
    validationSchema = joi.object({
      email: emailValidationSchema,
      password: passwordValidationSchema,
    });
  } else {
    validationSchema = joi.object({
      email: emailValidationSchema,
    });
  }

  const { t: translate } = useTranslation();
  const { formData, formValidation, validateForm } = useFormValidation(initialState, validationSchema, {
    abortEarly: false,
    stateDependencies: [showPassword],
  });

  const validationErrors = formatValidationErrorMessagesByKey(formValidation.error, translate);
  errors = showErrors && mergeErrors(errors, validationErrors);

  const handleEmailChange = useCallback(
    (value) => {
      const formIsValid = formData.email.input.onChange(value);
      onChange(value, password, formIsValid);
    },
    [onChange, password, formData.email.input],
  );

  const handlePasswordChange = (value) => {
    const formIsValid = formData.password.input.onChange(value);
    onChange(email, value, formIsValid);
  };

  const onTogglePasswordAnalytics = AnalyticsTracker.useOnClickTracker({
    eventLabel: 'clickShowPassword',
  });

  useEffect(() => {
    if (formValidation.valid === null) {
      validateForm();

      // If an initial value is provided for email and a callback function is provided,
      // notify the parent component that the input changed.
      if (email && onChange) {
        handleEmailChange(email);
      }
    }
  }, [email, formValidation.valid, handleEmailChange, onChange, validateForm]);

  // ID-66023: Prevent's autofill of browser-stored user/pass for all browsers if
  // the element names are non-standard.  Note: some browsers will require that both
  // the username and password fields be present before autofilling.
  const autoFillPrefix = preventAutofill ? 'not_a_' : '';
  const emailInputLabel = translations['emailLabel'] || translate(I18nKey.EmailAddress);

  return (
    <>
      <AnalyticsTracker.OnClick eventLabel="clickEmail">
        <EmailInput
          name={`${autoFillPrefix}username`}
          label={emailInputLabel}
          value={email}
          error={errors && errors.email}
          autoFocus={autoFocus}
          autoComplete={preventAutofill ? 'off' : 'username'}
          onChange={handleEmailChange}
          inputProps={{ 'data-test-id': 'email-input', 'aria-label': emailInputLabel }}
          disabled={disableEmail}
          size="small"
          margin="dense"
        />
      </AnalyticsTracker.OnClick>
      {showPassword && (
        <>
          <AnalyticsTracker.OnClick eventLabel="clickPassword">
            <PasswordInput
              name={`${autoFillPrefix}password`}
              value={password}
              error={errors && errors.password}
              autoComplete={`${preventAutofill ? 'new' : 'current'}-password`}
              size="small"
              margin="dense"
              inputProps={{ 'data-test-id': 'password-input', 'aria-label': translate(I18nKey.Password) }}
              onChange={handlePasswordChange}
              onToggleShowPassword={onTogglePasswordAnalytics}
            />
          </AnalyticsTracker.OnClick>
        </>
      )}
    </>
  );
};
