import React, { createContext, useContext, useEffect, useMemo } from 'react';

const analyticsContextDefaults = {
  site: 'accounts',
};

export const AnalyticsContext = createContext(analyticsContextDefaults);

export const AnalyticsProvider = ({ analytics, children, pageLanguage, site }) => {
  return <AnalyticsContext.Provider value={{ analytics, pageLanguage, site }}>{children}</AnalyticsContext.Provider>;
};

/**
 * @returns {import('@shutterstock-private/analytics-wrapper')}
 */
export function useAnalytics() {
  return useContext(AnalyticsContext).analytics;
}

export function usePageViewAnalytics(pageType, { subPage = '', isSensitive = undefined } = {}) {
  const { analytics, pageLanguage, site } = useContext(AnalyticsContext);
  const originalUrl = window.location.href;
  // TODO: `referrer` is populated automatically. Is this property still needed?
  const referringUrl = document.referrer;

  const payload = useMemo(
    () => ({
      originalUrl,
      pageLanguage,
      pageType,
      referringUrl,
      site,
      subPage,
    }),
    [originalUrl, pageLanguage, pageType, referringUrl, site, subPage],
  );

  if (isSensitive) {
    payload.isSensitive = isSensitive;
  }
  useEffect(() => {
    analytics.page(payload);
  }, [analytics, payload]);
}

/**
 * Record that a particular experiment has been viewed.
 * @param {string} experimentId unique experiment identifier. Does not need to be pre-registered. Consider including Jira Ticket.  Exe: IDN-0001-AB-Test-Thing
 * @param {string} variationId variant identifier.  The variant shown to the user.  Exe: Variant_1.  Can be a reactive property
 * @param {*} [extraOptions={}]
 * @param {string} [extraOptions.experimentName] optional nice name for experiment. If not provided, will reuse experimentId
 * @param {string} [extraOptions.source='launch-darkly'] optional source for experiment bucketing (assumes darkly).
 * @param {*|Function} [extraOptions.condition=true] optional condition that must be met before recording experiment.  Can be a react state prop or a function().
 */
export function useExperimentViewAnalytics(
  experimentId,
  variationId,
  { experimentName = '', source = 'launch-darkly', condition = true } = {},
) {
  const analytics = useAnalytics();

  useEffect(() => {
    // eval condition if function
    const conditionValue = typeof condition === 'function' ? condition() : condition;
    if (conditionValue) {
      analytics.experiment({
        experiment_id: experimentId,
        experiment_name: experimentName || experimentId,
        source,
        variation_id: variationId,
      });
    }
  }, [analytics, variationId, condition, experimentId, experimentName, source]);
}
