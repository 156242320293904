import React, { Suspense, lazy } from 'react';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from '../history';
import { createPreserveQueryHistory } from '../router';
import { DisableForEmployeeUsers } from './sso';
import { CanCreateUser } from '../account/create-user';
import { RequireAuth } from '../auth/RequireAuth';

const history = createPreserveQueryHistory(createBrowserHistory, ['flow', 'next', 'site', 'embedded'])();

const NotFoundPage = lazy(() => import('../error-pages/NotFoundPage'));

const CreateUserPage = lazy(() => import('./create-user').then(({ CreateUserPage }) => ({ default: CreateUserPage })));

const LoginPage = lazy(() => import('./login').then(({ LoginPage }) => ({ default: LoginPage })));

const OAuthAuthorizationCodeGrantConsentPage = lazy(() =>
  import('./oauth').then(({ OAuthAuthorizationCodeGrantConsentPage }) => ({
    default: OAuthAuthorizationCodeGrantConsentPage,
  })),
);

const OAuthErrorPage = lazy(() =>
  import('./oauth').then(({ OAuthErrorPage }) => ({
    default: OAuthErrorPage,
  })),
);

const MyAccountLayout = lazy(() =>
  import('./myaccount/layout').then(({ MyAccountLayout }) => ({ default: MyAccountLayout })),
);

const MyAccountHomePage = lazy(() =>
  import('./myaccount/home').then(({ MyAccountHomePage }) => ({ default: MyAccountHomePage })),
);

const PersonalInfoPage = lazy(() =>
  import('./myaccount/personal-info').then(({ PersonalInfoPage }) => ({ default: PersonalInfoPage })),
);

const SecurityPage = lazy(() => import('./myaccount/security').then(({ SecurityPage }) => ({ default: SecurityPage })));

const RemoveUserPageWelcome = lazy(() =>
  import('./remove-user').then(({ RemoveUserPageWelcome }) => ({ default: RemoveUserPageWelcome })),
);

const RemoveUserPageConfirm = lazy(() =>
  import('./remove-user').then(({ RemoveUserPageConfirm }) => ({ default: RemoveUserPageConfirm })),
);

const AdminPage = lazy(() =>
  import('./admin/Router').then(({ AdminRouter }) => ({
    default: AdminRouter,
  })),
);

const SamlPages = lazy(() =>
  import('./saml/Router').then(({ SamlRouter }) => ({
    default: SamlRouter,
  })),
);

const SigninRouter = lazy(() =>
  import('../lab/signin').then(({ SigninRouter }) => ({
    default: SigninRouter,
  })),
);

const UpdateEmail = lazy(() =>
  import('./update-email').then(({ UpdateEmail }) => ({
    default: UpdateEmail,
  })),
);

const UsersVerifications = lazy(() =>
  import('./users-verifications').then(({ UsersVerificationsPage }) => ({
    default: UsersVerificationsPage,
  })),
);

const UsersVerificationsCodePage = lazy(() =>
  import('./users-verifications').then(({ UsersVerificationsCodePage }) => ({
    default: UsersVerificationsCodePage,
  })),
);

const OtpPage = lazy(() =>
  import('./otp').then(({ OtpPage }) => ({
    default: OtpPage,
  })),
);
const OtpTriggerPage = lazy(() =>
  import('./otp').then(({ OtpTriggerPage }) => ({
    default: OtpTriggerPage,
  })),
);
const ForgotCredentials = lazy(() => import('./forgot-credentials'));
const ResetCredentials = lazy(() => import('./reset-credentials'));
const ChangeCredentials = lazy(() => import('./change-credentials'));
const UsersActivations = lazy(() => import('./users-activations'));
const UsersCurrentEmail = lazy(() => import('./users-current-email'));
const ChooseWorkspacePage = lazy(() => import('./choose-workspace'));
const SsoConfirmPage = lazy(() => import('./sso').then(({ SsoConfirmPage }) => ({ default: SsoConfirmPage })));

export function WwwRouter({ fullWidth }) {
  const verificationCode = new URLSearchParams(window.location.search).get('verification_code');

  return (
    <HistoryRouter history={history}>
      <Suspense fallback={<div />}>
        <Routes>
          <Route path="/oauth/authorization/consent" element={<OAuthAuthorizationCodeGrantConsentPage />} />
          <Route path="/oauth/error" element={<OAuthErrorPage />} />
          <Route
            path="/users/new"
            element={
              <CanCreateUser>
                <DisableForEmployeeUsers>
                  <CreateUserPage verificationCode={verificationCode} fullWidth={fullWidth} />
                </DisableForEmployeeUsers>
              </CanCreateUser>
            }
          />
          <Route
            path="/login"
            element={
              <DisableForEmployeeUsers>
                <LoginPage fullWidth={fullWidth} />
              </DisableForEmployeeUsers>
            }
          />
          <Route
            path="/myaccount"
            element={
              <RequireAuth>
                <MyAccountLayout />
              </RequireAuth>
            }>
            <Route
              index
              element={
                <RequireAuth>
                  <MyAccountHomePage />
                </RequireAuth>
              }
            />
            <Route
              path="personal-info"
              element={
                <RequireAuth>
                  <PersonalInfoPage />
                </RequireAuth>
              }
            />
            <Route
              path="security"
              element={
                <RequireAuth>
                  <SecurityPage />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/remove" element={<RemoveUserPageWelcome />} />
          <Route path="/remove/confirm" element={<RemoveUserPageConfirm />} />
          <Route path="/admin/*" element={<AdminPage />} />
          <Route path="/saml/*" element={<SamlPages />} />
          <Route path="/users/current/verifications/email" element={<UsersVerifications />} />
          <Route path="/users/verifications/:code" element={<UsersVerificationsCodePage />} />
          <Route path="/users/verifications/" element={<UsersVerificationsCodePage />} />
          <Route
            path="/choose-workspace"
            key="choose-workspace"
            element={<ChooseWorkspacePage fullWidth={fullWidth} />}
          />
          ,
          <Route path="/otp" key="/otp" element={<OtpPage fullWidth={fullWidth} />} />,
          <Route path="/otp/trigger" key="/otp/trigger" element={<OtpTriggerPage fullWidth={fullWidth} />} />,
          <Route
            path="/credentials/forgot"
            key="/credentials/forgot"
            element={
              <DisableForEmployeeUsers>
                <ForgotCredentials fullWidth={fullWidth} />
              </DisableForEmployeeUsers>
            }
          />
          ,
          <Route
            path="/credentials/reset"
            key="/credentials/reset"
            element={<ResetCredentials fullWidth={fullWidth} />}
          />
          ,
          <Route
            path="/credentials/change"
            key="/credentials/change"
            element={
              <DisableForEmployeeUsers>
                <ChangeCredentials />
              </DisableForEmployeeUsers>
            }
          />
          ,
          <Route path="/users/activations/:code" key="/users/activations/:code" element={<UsersActivations />} />,
          <Route path="/users/activations/" key="/users/activations" element={<UsersActivations />} />,
          <Route
            path="/users/current/email"
            key="/users/current/email"
            element={
              <DisableForEmployeeUsers>
                <UsersCurrentEmail />
              </DisableForEmployeeUsers>
            }
          />
          ,
          <Route
            path="/sso/:identityProviderName/confirm"
            key="/sso/:identityProviderName/confirm"
            element={<SsoConfirmPage />}
          />
          ,
          <Route path="/v2/update-email" element={<UpdateEmail />} />,
          <Route path="/v2/*" element={<SigninRouter />} />,
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </HistoryRouter>
  );
}
