import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n';
import TextField from '@mui/material/TextField';

export const EmailInput = ({ error, floatLabel = false, label, value, onChange, ...props }) => {
  const { t: translate } = useTranslation();

  label = label || translate(I18nKey.EmailAddress);

  if (Array.isArray(error)) {
    error = error[0];
  }

  return (
    <>
      <TextField
        type="email"
        label={label}
        value={value}
        error={!!error}
        helperText={error}
        fullWidth
        onChange={(event) => onChange(event.currentTarget.value)}
        InputLabelProps={{ shrink: !floatLabel }}
        inputProps={props.inputProps}
        {...props}
      />
    </>
  );
};
