import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Recaptcha from 'react-google-recaptcha';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup from '@mui/material/FormGroup';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import CheckIcon from '@mui/icons-material/Check';
import joi from 'joi';
import _ from 'lodash';
import { CredentialsInput } from '../CredentialsInput';
import {
  Form,
  FormAction,
  FormBody,
  FormFooter,
  FormHeader,
  FormTitle,
  formatValidationErrorMessagesByKey,
  useFormValidation,
} from '../../core-components/form';
import { MarketingEmailOptIn } from '../../marketing';
import { I18nKey } from '../../i18n';
import { AnalyticsTracker } from '../../analytics/trackers';
import { ExistingUserLoginLink, SocialLogin } from '../login';
import { CreateUserTermsOfService } from './CreateUserTermsOfService';
import classes from './CreateUserForm.module.scss';

import { ExperimentContainer } from '../../lab';
import {
  experimentRound2 as socialButtonExperimentRound2,
  SocialLoginTop,
} from '../../lab/experiments/IDN-4938-social-button-placement';

const fullNameValidationSchema = joi.string().max(255).required();
const displayNameValidationSchema = joi.string().max(25).required();
const tosAckValidationSchema = joi.boolean().invalid(false);

export const CreateUserForm = ({
  languageCode,
  email = '',
  loading,
  errors,
  inputErrors,
  webPropertyDisplayName = '',
  marketingEmailOptInInitialValue = false,
  openLinksInNewTab,
  openSocialLoginInNewTab,
  recaptchaSiteKey,
  disableEmail = false,
  showDisplayName = false,
  showFullName = false,
  showMarketingEmailOptIn = false,
  showPassword = true,
  showRecaptcha,
  showSocialLogin = true,
  showFormTitle = true,
  termsOfServiceAction = 'none',
  validateDisplayName = () => [],
  onSubmit,
  translations = {
    formTitle: '',
    submitButtonText: '',
  },
}) => {
  const initialFormState = {
    ...(showFullName ? { fullName: '' } : {}),
    ...(showDisplayName ? { displayName: '' } : {}),
    ...(termsOfServiceAction === 'explicit' ? { agreeToTos: false } : {}),
  };

  const schema = joi.object({
    ...(showFullName ? { fullName: fullNameValidationSchema } : {}),
    ...(showDisplayName ? { displayName: displayNameValidationSchema } : {}),
    ...(termsOfServiceAction === 'explicit' ? { agreeToTos: tosAckValidationSchema } : {}),
  });

  const initialFormData = {
    ...initialFormState,
    email,
    password: '',
    recaptchaValue: '',
    // When the opt-in option is not shown, always opt the user in.
    marketingEmailOptIn: showMarketingEmailOptIn ? marketingEmailOptInInitialValue : true,
    valid: false,
  };

  const { t: translate } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const {
    formData: form,
    formValidation,
    validateForm,
  } = useFormValidation(_.pick(formData, Object.keys(initialFormState)), schema, {
    abortEarly: false,
    stateDependencies: [showDisplayName, showFullName],
  });

  const displayNameRef = useRef(formData.displayName);
  const [externalValidationResults, setExternalValidationResults] = useState({});

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationErrors = formatValidationErrorMessagesByKey(formValidation.error, translate);
  const isSubmitDisabled = () =>
    (showRecaptcha && !formData.recaptchaValue) || !!loading || !!externalValidationResults?.displayName?.running;

  const getMarketingEmailOptIn = () => {
    if (showMarketingEmailOptIn) {
      return (
        <MarketingEmailOptIn
          value={formData.marketingEmailOptIn}
          onChange={(value) => setFormData({ ...formData, marketingEmailOptIn: value })}
        />
      );
    }
  };

  const validateDisplayNameInput = async () => {
    setExternalValidationResults({ ...externalValidationResults, displayName: { running: true } });

    const displayNameErrors = await validateDisplayName(formData.displayName).catch(() => []);

    if (displayNameRef.current === formData.displayName) {
      const mappedErrors = displayNameErrors.map((err) => (err.code ? translate(err.code.toLowerCase()) : err.text));
      setExternalValidationResults({
        ...externalValidationResults,
        displayName: { running: false, completed: true, errors: mappedErrors },
      });
    }
  };

  const handleInputChange = (field, value) => {
    const newFormData = { ...formData, [field]: value };
    form[field].input.onChange(value);

    setFormData({ ...newFormData });
  };

  const handleDisplayNameChange = (value) => {
    displayNameRef.current = value;

    handleInputChange('displayName', value);
  };

  const handleCredentialsChange = (email, password, credentialsValid) => {
    const newFormData = {
      ...formData,
      email,
      password,
      credentialsValid,
    };

    setFormData(newFormData);
  };

  const handleRecaptchaChange = (value) => {
    const newFormData = {
      ...formData,
      recaptchaValue: value,
    };

    setFormData(newFormData);
  };

  const onTosAckChange = () => {
    const newValue = !formData.agreeToTos;

    form.agreeToTos.input.onChange(newValue);
    setFormData({ ...formData, agreeToTos: newValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    const { credentialsValid, ...restFormData } = formData;
    const hasValidationErrors = Object.values(validationErrors || {}).some((errors) => !!errors.length);
    const hasRuntimeErrors = Object.values(externalValidationResults || {}).some((key) => !!key?.errors?.length);
    const valid = credentialsValid && !hasValidationErrors && !hasRuntimeErrors;

    onSubmit({ ...restFormData, valid });
  };

  let alerts;
  if (errors) {
    alerts = errors
      .map((error) => (error.i18nKey ? translate(error.i18nKey) : error))
      .filter((text) => typeof text === 'string' && text)
      .map((text, i) => (
        <Alert severity="warning" key={i}>
          {text}
        </Alert>
      ));
  }

  const extractError = (field) => inputErrors?.[field] || validationErrors?.[field]?.[0];

  const fullNameError = submitted && extractError('fullName');
  const displayNameError =
    externalValidationResults?.displayName?.errors?.[0] || (submitted && extractError('displayName'));
  const tosAckError = submitted && extractError('agreeToTos');

  const getDisplayNameIcon = () => {
    if (externalValidationResults?.displayName?.running) {
      return <PendingIcon color="warning" />;
    } else if (externalValidationResults?.displayName?.errors[0]) {
      return <CancelIcon color="error" />;
    } else if (externalValidationResults?.displayName?.completed && formData.displayName) {
      return <CheckIcon color="success" />;
    }
  };
  const displayNameIcon = getDisplayNameIcon();

  return (
    <div>
      <Form onSubmit={handleSubmit} noValidate={true} data-test-id="create-user-form">
        <FormHeader align="left">
          {showFormTitle && <FormTitle>{translations.formTitle || translate(I18nKey.CreateYourFreeAccount)}</FormTitle>}
        </FormHeader>
        <FormBody>
          {showSocialLogin && (
            <ExperimentContainer
              experimentId={socialButtonExperimentRound2.id}
              experimentName={socialButtonExperimentRound2.experimentName}
              featureFlagKey={socialButtonExperimentRound2.featureFlagKey}
              featureFlagVariation={socialButtonExperimentRound2.featureFlagVariation.VariantTop}
              seen={true}>
              <SocialLoginTop openSocialLoginInNewTab={openSocialLoginInNewTab} analyticsVariant="CreateUser" />
            </ExperimentContainer>
          )}
          {alerts && <div className={classes.alertWrapper}>{alerts}</div>}
          <div className={classes.inputWrapper}>
            {showFullName && (
              <TextField
                autoFocus
                name="full_name"
                autoComplete={'off'}
                label={translate(I18nKey.FullNameLabel)}
                value={formData.fullName}
                margin="dense"
                error={!!fullNameError}
                InputLabelProps={{ shrink: true }}
                helperText={fullNameError}
                fullWidth
                onChange={(event) => handleInputChange('fullName', event.currentTarget.value)}
              />
            )}
            {showDisplayName && (
              <TextField
                name="display_name"
                autoComplete={'off'}
                autoFocus={!showFullName}
                label={
                  <Tooltip placement="right-start" title={translate(I18nKey.DisplayNameTooltip)}>
                    <div>{translate(I18nKey.DisplayNameLabel)}</div>
                  </Tooltip>
                }
                value={formData.displayName}
                InputLabelProps={{ shrink: true }}
                error={!!displayNameError}
                onBlur={() => validateDisplayNameInput()}
                InputProps={{
                  endAdornment: displayNameIcon && (
                    <InputAdornment position="end">
                      <IconButton size="large">{displayNameIcon}</IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={displayNameError}
                fullWidth
                size="small"
                margin="dense"
                onChange={(event) => handleDisplayNameChange(event.currentTarget.value)}
              />
            )}
            <CredentialsInput
              autoFocus={!showDisplayName && !showFullName}
              disableEmail={disableEmail}
              email={formData.email}
              password={formData.password}
              showPassword={showPassword}
              showErrors={submitted}
              preventAutofill={true}
              errors={inputErrors}
              onChange={handleCredentialsChange}
            />
            {getMarketingEmailOptIn()}
            {showRecaptcha && recaptchaSiteKey && (
              <div className={classes.recaptchaWrapper}>
                <Recaptcha
                  sitekey={recaptchaSiteKey}
                  render="explicit"
                  hl={languageCode}
                  onChange={handleRecaptchaChange}
                />
              </div>
            )}
          </div>
          {termsOfServiceAction === 'explicit' && (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="agree_to_tos" checked={formData.agreeToTos} onChange={onTosAckChange} />}
                label={<CreateUserTermsOfService />}
              />
              <FormHelperText error={!!tosAckError}>{tosAckError}</FormHelperText>
            </FormGroup>
          )}
          <FormAction>
            <div className={classes.bottomSpacingSm}>
              <AnalyticsTracker.OnClick eventLabel="clickCreateUser">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitDisabled()}
                  data-test-id="create-user-form-submit-button">
                  {translations.submitButtonText || translate(I18nKey.GetStarted)}
                </Button>
              </AnalyticsTracker.OnClick>
            </div>
            {showSocialLogin && (
              <ExperimentContainer
                experimentId={socialButtonExperimentRound2.id}
                experimentName={socialButtonExperimentRound2.experimentName}
                featureFlagKey={socialButtonExperimentRound2.featureFlagKey}
                featureFlagVariation={socialButtonExperimentRound2.featureFlagVariation.Control}
                seen={true}>
                <SocialLogin openSocialLoginInNewTab={openSocialLoginInNewTab} analyticsVariant="CreateUser" />
              </ExperimentContainer>
            )}
          </FormAction>
          {termsOfServiceAction === 'implicit' && <CreateUserTermsOfService />}
        </FormBody>
        <FormFooter>
          <AnalyticsTracker.OnClick eventLabel="clickGotoLogin">
            <ExistingUserLoginLink openInNewTab={openLinksInNewTab} />
          </AnalyticsTracker.OnClick>
        </FormFooter>
      </Form>
    </div>
  );
};
